import React from "react";
import * as styles from "../styles/footer.module.css";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className={styles.footerContainer}>
      <div className={styles.infoContainer}>
        <span className={styles.logoContainer}>
          <StaticImage
            src="../assets/wdtwitter.png"
            alt="Picture of the blog author - Henri Parviainen"
            className={styles.logoWD}
          />
          <span>Webdevolution</span>
        </span>
        <div className={styles.infoText}>
          <h4 className={styles.footerHeader}>About</h4>
          <p className={styles.footerP}>
            Webdevolution offers programming and web development tutorials, tips
            and solutions for common problems in the form of blog articles.
          </p>
          <h4 className={styles.footerHeader}>Topics</h4>
          <p className={styles.footerP}>
            Javascript, Typescript, React, C#, CSS, Gatsby
          </p>
          <h4 className={styles.footerHeader}>Advertisement</h4>
          <p className={styles.footerP}>
            Webdevolution is a participant in the Amazon Services LLC Associates
            Program, an affiliate advertising program designed to provide a
            means for sites to earn advertising fees by advertising and linking
            to Amazon.com. As an Amazon Associate, we earn from qualifying
            purchases.
          </p>
        </div>
        <div className={styles.socialContainer}>
          <h4 className={styles.footerHeader}>Social</h4>
          <div className={styles.socialLogoContainer}>
            <a
              className={styles.socialLink}
              href="https://twitter.com/webdevolution_"
            >
              <span className={styles.socialLogoTwitter} alt="Twitter logo">
                {" "}
              </span>
            </a>
            <a className={styles.socialLink} href="/blog/rss-feed.xml">
              <span className={styles.socialLogoRSS} alt="RSS feed logo">
                {" "}
              </span>
            </a>
          </div>

          <span className={styles.featured}>
            <a
              className={styles.featuredLink}
              href="https://www.tech-blogs.dev"
            >
              <StaticImage
                src="../assets/featured-on-tech-blogs.png"
                alt="tech blogs"
                className={styles.techBlogsLogo}
                placeholder="blurred"
              />
            </a>
          </span>
        </div>
      </div>
      <span className={styles.copyright}>&#169; {year} Webdevolution</span>
    </div>
  );
};
export default Footer;
