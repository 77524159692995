import React from "react";
import Header from "./header";

const HeaderWrapper = ({ children, props }) => {
  return (
    <div>
      <Header />
      {children}
    </div>
  );
};

export default HeaderWrapper;
