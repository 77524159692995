// extracted by mini-css-extract-plugin
export var copyright = "footer-module--copyright--43e5e";
export var featured = "footer-module--featured--d0a46";
export var footerContainer = "footer-module--footerContainer --5765b";
export var footerHeader = "footer-module--footerHeader--a084a";
export var footerLi = "footer-module--footerLi--bcab6";
export var footerP = "footer-module--footerP--278cc";
export var footerUl = "footer-module--footerUl--6071a";
export var infoContainer = "footer-module--infoContainer--14bed";
export var infoText = "footer-module--infoText--548cb";
export var link = "footer-module--link--08adf";
export var logoContainer = "footer-module--logoContainer--abb2d";
export var logoWD = "footer-module--logoWD--b432a";
export var profileImg = "footer-module--profileImg--6334f";
export var socialContainer = "footer-module--socialContainer--b40e7";
export var socialLink = "footer-module--socialLink--3cdaf";
export var socialLogo = "footer-module--socialLogo--43b4c";
export var socialLogoContainer = "footer-module--socialLogoContainer--43ee5";
export var socialLogoRSS = "footer-module--socialLogoRSS--01d6b";
export var socialLogoTwitter = "footer-module--socialLogoTwitter--b5dd9";
export var socialLogoYoutube = "footer-module--socialLogoYoutube--d1ce7";
export var techBlogsLogo = "footer-module--techBlogsLogo--3e8ba";