exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-backend-js": () => import("./../../../src/pages/backend.js" /* webpackChunkName: "component---src-pages-backend-js" */),
  "component---src-pages-databases-js": () => import("./../../../src/pages/databases.js" /* webpackChunkName: "component---src-pages-databases-js" */),
  "component---src-pages-frontend-js": () => import("./../../../src/pages/frontend.js" /* webpackChunkName: "component---src-pages-frontend-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lifestyle-js": () => import("./../../../src/pages/lifestyle.js" /* webpackChunkName: "component---src-pages-lifestyle-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */)
}

